
let cloudName = process.env.VUE_APP_CLOUDINARY_CLOUD_NAME
import axios from 'axios'

export default {
    async uploadToCloudinary(file) {
        

        let cloudinaryUrl = `http://api.cloudinary.com/v1_1/${cloudName}/image/upload`

        let config = {
            headers: {
                "Content-type": "multipart/form-data",
            },
        };

        try {
            let res = await axios.post(cloudinaryUrl, file, config);
            return res.data.url;
        } catch (error) {
            console.log(error)
        }
    }
}
