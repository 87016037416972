<template>
  <div>
    <v-container v-if="failed == false">
      <v-file-input
        accept="image/*"
        label="Upload Receipt"
        v-model="file"
        persistent-hint
        hint="The image size should be less than 4MB"
        show-size
      ></v-file-input>
      <v-row>
        <v-col class="d-flex justify-end" sm-12 md-12 lg-12>
          <v-btn :disabled="file == null" color="primary" @click="uploadImage"
            >Upload</v-btn
          >
        </v-col>
      </v-row>

    </v-container>


    <div v-if="failed == true">
      <v-card class="mx-auto" color="#A0595F" dark max-width="400">
        <v-card-title>
          <v-icon lare left> mdi-alert-circle-outline </v-icon>
          <span class="text-h5 font-weight-bold">Error</span>
        </v-card-title>

        <v-card-text
          class="text-h5 font-weight-light"
          v-html="$t('receipt_invalid_reasons')"
        >
        </v-card-text>
        <v-card-actions>
          <v-list-item class="grow">
            <v-row align="center" justify="end">
              <v-btn
                class="mx-2"
                dark
                rounded
                color="blue-grey"
                @click="goBack()"
              >
                Go Back
                <v-icon dark> mdi-step-backward </v-icon>
              </v-btn>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>


<script>
import { ReviewDataService } from "../../services/DataServiceiReview";
import cloudinary from '../../Util/cloudinary'

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {
      file: null,
      failed: false,
      error: '',
      imageUrl: '',
    };
  },

  mounted() {
    console.log(JSON.stringify(this.$route.params));
  },
  
  methods: {
    async uploadImage() {
     
      this.$store.state.loader = true;

      let formy = new FormData();
      formy.append("file", this.file);
      formy.append("upload_preset", process.env.VUE_APP_CLOUDINARY_PRESET);

      let image_url = await cloudinary.uploadToCloudinary(formy)
      this.imageUrl = image_url

      this.process();

    },

    async process() {

      let dataToSend = {
        image: this.imageUrl,
        data: this.$route.params.data,
        userId: this.$store.state.userProfile._id
      }

      reviewDataService.detectText(dataToSend).then(res => {
        if (res.data) {
          this.$router.push({
            name: "startReview",
            params: this.$route.params,
          })
        } else {
           this.failed = true;
        }
        this.$store.state.loader = false;
      })
    },
    goBack() {
      this.$router.push({ name: "iReviewLanding" });
    },
  },
};
</script>